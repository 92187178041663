import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FCM_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FCM_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FCM_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_FCM_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FCM_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_FCM_APPID,
  measurementId: process.env.NEXT_PUBLIC_FCM_MEASUREMENTID,
}

const app = initializeApp(firebaseConfig)

export { app, firebaseConfig }
