import * as React from 'react'
import { app } from '../utils/firebase'
import {
  getMessaging,
  onMessage,
  getToken,
  deleteToken,
} from 'firebase/messaging'
import {
  createPushNotificationToken,
  deletePushNotificationToken,
} from '../services/api'
import * as auth from '../utils/auth'

const FCMContext = React.createContext<
  | {
      requestPushToken: Function
      deletePushToken: Function
    }
  | undefined
>(undefined)

const FCMProvider = ({ children }: any) => {
  const [messaging, setMessaging] = React.useState<any>(null)

  React.useEffect(() => {
    if (!('serviceWorker' in navigator)) {
      return
    }

    const messaging = getMessaging(app)
    setMessaging(messaging)

    onMessage(messaging, async (payload: any) => {
      const notification = new Notification(payload.notification.title, {
        body: payload.notification.body,
        image: payload.notification.image,
      })
      notification.addEventListener('click', () => {
        window.open(payload.data.link)
      })
    })
  }, [])

  const requestPushToken = async () => {
    if (!('Notification' in window) || messaging === null) {
      return
    }

    const token = await getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FCM_VAPID,
    })

    if (token) {
      const authToken = auth.getAuthToken()
      await createPushNotificationToken({ token }, authToken)
    }
  }

  const deletePushToken = async () => {
    if (!('Notification' in window) || messaging === null) {
      return
    }

    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.NEXT_PUBLIC_FCM_VAPID,
      })

      const isDeleted = await deleteToken(messaging)
      if (isDeleted) {
        const authToken = auth.getAuthToken()
        await deletePushNotificationToken(token, authToken)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <FCMContext.Provider
      value={{
        requestPushToken,
        deletePushToken,
      }}
    >
      {children}
    </FCMContext.Provider>
  )
}

function useFCM() {
  const context = React.useContext(FCMContext)
  if (context === undefined) {
    throw new Error('useFCM must be used within a FCMProvider')
  }
  return context
}

export { FCMProvider, useFCM }
