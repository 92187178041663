import * as React from 'react'
import * as auth from '../utils/auth'
import { useQuery } from 'react-query'
import { getUser } from '../services/api'
import type { User } from '../types/user.type'

const UserContext = React.createContext<
  | {
      login: Function
      logout: Function
      user: User | null | undefined
      setUser: Function
    }
  | undefined
>(undefined)

const UserProvider = ({ children }: any) => {
  /**
   * User state:
   * - user: user object
   * - null: initial value, loading
   * - undefined: not logged in, error
   */
  const [user, setUser] = React.useState<null | undefined>(null)
  const authToken = auth.getAuthToken()

  const { data, isSuccess, isError, isLoading, isIdle } = useQuery(
    'user',
    () => getUser(authToken),
    {
      enabled: !!authToken,
      retry: false,
    }
  )

  const login = async (data: any) => {
    const { access_token, user } = data
    setUser(user)
    auth.setAuthToken(access_token)
  }

  const logout = async () => {
    setUser(undefined)
    auth.removeAuthToken()
  }

  React.useEffect(() => {
    if (isSuccess) {
      setUser(data)
    }
    if (isLoading) {
      setUser(null)
    }
    if (isError || isIdle) {
      setUser(undefined)
      auth.removeAuthToken()
    }
  }, [isSuccess, isError, isLoading, isIdle])

  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUser }
