import * as React from 'react'
import { useQuery } from 'react-query'
import { getTopics } from '../services/api'

const TopicsContext = React.createContext<
  | {
      isSingleTopic: null | boolean
      topic: any
      topics: any
    }
  | undefined
>(undefined)

const TopicsProvider = ({ children }: any) => {
  const [isSingleTopic, setIsSingleTopic] = React.useState<null | boolean>(null)

  const { data: topics } = useQuery('topics', () => getTopics())
  let topic = topics ? topics[0] : null

  React.useEffect(() => {
    if (topics) {
      setIsSingleTopic(topics.length === 1)
    }
  }, [topics])

  return (
    <TopicsContext.Provider
      value={{
        isSingleTopic,
        topic,
        topics,
      }}
    >
      {children}
    </TopicsContext.Provider>
  )
}

function useTopics() {
  const context = React.useContext(TopicsContext)
  if (context === undefined) {
    throw new Error('useTopics must be used within a TopicsProvider')
  }
  return context
}

export { TopicsProvider, useTopics }
