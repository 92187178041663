const AUTH_TOKEN_KEY = 'authToken'
const isBrowser = () => typeof window !== 'undefined'

export const getAuthToken = () => {
  if (!isBrowser()) {
    return null
  }

  return window.localStorage.getItem(AUTH_TOKEN_KEY)
}

export const setAuthToken = (token: string) => {
  window.localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export const removeAuthToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN_KEY)
}
