import * as apiProvider from './provider'

export const loginUser = async function (data: any) {
  return apiProvider.post('/auth/login', data)
}

export const registerUser = async function (data: any) {
  return apiProvider.post('/auth/register', data)
}

export const verifyUser = async function (token: string) {
  return apiProvider.get(`/auth/verify/${token}`)
}

export const getUser = async function (token: string | null) {
  return apiProvider.get('/users/me', token)
}

export const getUserById = async function (
  userId: number,
  token: string | null
) {
  return apiProvider.get(`/users/${userId}`, token)
}

export const createUser = async function (data: any, token: string | null) {
  return apiProvider.post('/users', data, token)
}

export const updateUser = async function (
  userId: number,
  data: any,
  token: string | null
) {
  return apiProvider.patch(`/users/${userId}`, data, token)
}

export const getAllUsers = async function (token: string | null, page: number) {
  return apiProvider.get(`/users?page=${page}`, token)
}

export const sendResetPasswordEmail = async function (email: string) {
  return apiProvider.post(`/users/reset-password`, {
    email,
  })
}

export const resetPassword = async function (
  password: string,
  passwordConfirm: string,
  token: string
) {
  return apiProvider.post(`/auth/reset-password/${token}`, {
    password,
    passwordConfirm,
  })
}

export const getFields = async function () {
  return apiProvider.get(`/fields`)
}

export const getField = async function (fieldId: number) {
  return apiProvider.get(`/fields/${fieldId}`)
}

export const createField = async function (data: any, token: string | null) {
  return apiProvider.post(`/fields`, data, token)
}

export const updateField = async function (
  fieldId: number,
  data: any,
  token: string | null
) {
  return apiProvider.patch(`/fields/${fieldId}`, data, token)
}

export const deleteField = async function (
  fieldId: number,
  token: string | null
) {
  return apiProvider.remove(`/fields/${fieldId}`, token)
}

export const getTopics = async function (location: number = 0) {
  return apiProvider.get(`/topics?location=${location}`)
}

export const getTopicBySlug = async function (slug: string) {
  return apiProvider.get(`/topics/slug/${slug}`)
}

export const getFieldsByTopic = async function (topicId: number) {
  return apiProvider.get(`/fields/topic/${topicId}`)
}

export const getSwitchField = async function (
  fieldId: number,
  token: string | null
) {
  return apiProvider.get(`/fields/switch/field/${fieldId}`, token)
}

export const createSwitchField = async function (
  fieldId: number,
  data: any,
  token: string | null
) {
  return apiProvider.post(`/fields/switch/field/${fieldId}`, data, token)
}

export const updateSwitchField = async function (
  switchFieldId: number,
  data: any,
  token: string | null
) {
  return apiProvider.patch(`/fields/switch/${switchFieldId}`, data, token)
}

export const getUserNotification = async function (
  notificationId: number | undefined,
  token: string | null
) {
  return apiProvider.get(`/user-notifications/${notificationId}`, token)
}

export const getUserNotifications = async function (
  userId: number | undefined,
  searchQuery: string,
  token: string | null,
  page: number
) {
  return apiProvider.get(
    `/user-notifications/user/${userId}?page=${page}${
      searchQuery !== '' ? `&search=${searchQuery}` : ''
    }`,
    token
  )
}

export const deleteUserNotification = async function (
  notificationId: number,
  token: string | null
) {
  return apiProvider.remove(`/user-notifications/${notificationId}`, token)
}

export const markUserNotificationAsRead = async function (
  notificationId: number | undefined,
  data: { read: boolean },
  token: string | null
) {
  return apiProvider.patch(`/user-notifications/${notificationId}`, data, token)
}

export const markAllUserNotificationsAsRead = async function (
  token: string | null
) {
  return apiProvider.patch(`/user-notifications/update-all-as-read`, {}, token)
}

export const getNotifications = async function (
  token: string | null,
  page: number
) {
  return apiProvider.get(`/notifications/?page=${page}`, token)
}

export const getNotificationsByTopicId = async function (
  topicId: number,
  token: string | null,
  page: number
) {
  return apiProvider.get(`/notifications/topic/${topicId}?page=${page}`, token)
}

export const getNotification = async function (
  notificationId: number | undefined,
  token: string | null
) {
  return apiProvider.get(`/notifications/${notificationId}`, token)
}

export const getUsersByNotification = async function (
  data: any,
  token: string | null
) {
  return apiProvider.post('/users/filters', data, token)
}

export const getDeliveryByNotification = async function (
  notificationId: number | undefined,
  token: string | null
) {
  return apiProvider.get(`/deliveries/notification/${notificationId}`, token)
}

export const createDelivery = async function (data: any, token: string | null) {
  return apiProvider.post('/deliveries', data, token)
}

export const getDeliveryStatistic = async function (token: string | null) {
  return apiProvider.get(`/deliveries/statistic/`, token)
}

export const createPushNotificationToken = async function (
  data: any,
  token: string | null
) {
  return apiProvider.post('/push-notification-tokens', data, token)
}

export const deletePushNotificationToken = async function (
  data: any,
  token: string | null
) {
  return apiProvider.remove(`/push-notification-tokens/${data}`, token)
}

export const getSelectFieldOptions = async function (fieldId: number) {
  return apiProvider.get(`/fields/select-settings/options/field/${fieldId}`)
}

export const parseSelectFieldOptions = async function (
  selectSettingId: number,
  data: any,
  token: string | null
) {
  return apiProvider.post(
    `/fields/select-settings/options/select-setting/${selectSettingId}/parse`,
    data,
    token
  )
}

export const createSelectFieldOption = async function (
  fieldId: number,
  data: any,
  token: string | null
) {
  return apiProvider.post(
    `/fields/select-settings/options/field/${fieldId}`,
    data,
    token
  )
}

export const updateSelectFieldOption = async function (
  optionId: number,
  data: any,
  token: string | null
) {
  return apiProvider.patch(
    `/fields/select-settings/options/${optionId}`,
    data,
    token
  )
}

export const deleteSelectFieldOption = async function (
  optionId: number,
  token: string | null
) {
  return apiProvider.remove(
    `/fields/select-settings/options/${optionId}`,
    token
  )
}

export const getSelectField = async function (
  fieldId: number,
  token: string | null
) {
  return apiProvider.get(`/fields/select/field/${fieldId}`, token)
}

export const updateSelectField = async function (
  fieldId: number,
  data: any,
  token: string | null
) {
  return apiProvider.patch(`/fields/select/${fieldId}`, data, token)
}

export const createSelectField = async function (
  fieldId: number,
  data: any,
  token: string | null
) {
  return apiProvider.post(`/fields/select/field/${fieldId}`, data, token)
}

export const deleteSelectField = async function (
  fieldId: number,
  token: string | null
) {
  return apiProvider.remove(`/fields/select/${fieldId}`, token)
}

export const createAndUpdateSelectField = async function (
  fieldId: number,
  data: any,
  token: string | null
) {
  return apiProvider.post(`/fields/select/field/${fieldId}/bulk`, data, token)
}

export const createSelectFieldSetting = async function (
  fieldId: number,
  data: any,
  token: string | null
) {
  return apiProvider.post(
    `/fields/select-settings/field/${fieldId}`,
    data,
    token
  )
}

export const updateSelectFieldSetting = async function (
  settingId: number,
  data: any,
  token: string | null
) {
  return apiProvider.patch(`/fields/select-settings/${settingId}`, data, token)
}

export const getNotificationChannels = async function () {
  return apiProvider.get('/notification-channels')
}

export const getLocations = async function () {
  return apiProvider.get('/locations')
}

export const getUserNotificationChannels = async function (
  userId: number | undefined,
  token: string | null
) {
  return apiProvider.get(`/users/${userId}/notification-channels`, token)
}

export const assignNotificationChannel = async function (
  notificationChannelId: number,
  userId: number | undefined,
  token: string | null
) {
  return apiProvider.post(
    `/users/${userId}/notification-channels/${notificationChannelId}`,
    {},
    token
  )
}

export const unassignNotificationChannel = async function (
  notificationChannelId: number,
  userId: number | undefined,
  token: string | null
) {
  return apiProvider.remove(
    `/users/${userId}/notification-channels/${notificationChannelId}`,
    token
  )
}

export const getViberMessageStatistic = async function (
  viberNotificationId: number | undefined,
  token: string | null
) {
  return apiProvider.get(
    `/viber-messages/statistic/viberNotification/${viberNotificationId}`,
    token
  )
}

export const getViberAccountsByUserId = async function (
  userId: number | undefined,
  token: string | null
) {
  return apiProvider.get(`/viber-subscribers/accounts/${userId}`, token)
}

export const updateViberSubscription = async function (
  data: {
    accountId: number
    data: {
      subscribed: boolean
    }
  },
  token: string | null
) {
  return apiProvider.patch(
    `/viber-subscribers/update/${data.accountId}`,
    data.data,
    token
  )
}

export const getPushNotificationMessageStatistic = async function (
  pushNotificationId: number | undefined,
  token: string | null
) {
  return apiProvider.get(
    `/push-notification-messages/statistic/pushNotification/${pushNotificationId}`,
    token
  )
}
