const API_URL = process.env.NEXT_PUBLIC_API_URL

async function get(path: string, token: string | null = null) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  const response = await fetch(`${API_URL}${path}`, {
    method: 'GET',
    headers,
  })

  const data = await response.json()

  if (!response.ok) {
    const error = Array.isArray(data.message) ? data.message[0] : data.message
    throw new Error(error)
  }

  return data
}

async function post(path: string, params: any, token: string | null = null) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  const response = await fetch(`${API_URL}${path}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  })

  const data = await response.json()

  if (!response.ok) {
    const error = Array.isArray(data.message) ? data.message[0] : data.message
    throw new Error(error)
  }

  return data
}

async function patch(path: string, params: any, token: string | null = null) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  const response = await fetch(`${API_URL}${path}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(params),
  })

  const data = await response.json()

  if (!response.ok) {
    const error = Array.isArray(data.message) ? data.message[0] : data.message
    throw new Error(error)
  }

  return data
}

async function remove(path: string, token: string | null = null) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  const response = await fetch(`${API_URL}${path}`, {
    method: 'DELETE',
    headers,
  })

  const data = await response.json()

  if (!response.ok) {
    const error = Array.isArray(data.message) ? data.message[0] : data.message
    throw new Error(error)
  }

  return data
}

export { get, post, patch, remove, API_URL }
