import * as React from 'react'
import type { AppProps } from 'next/app'
import { QueryClientProvider, QueryClient } from 'react-query'
import { UserProvider } from '../src/context/user'
import { FCMProvider } from '../src/context/FCM'
import { TopicsProvider } from '../src/context/topics'
import { ReactQueryDevtools } from 'react-query/devtools'
import { appWithTranslation } from 'next-i18next'
import SSRProvider from 'react-bootstrap/SSRProvider'
import '../src/scss/index.scss'
import { getAnalytics } from 'firebase/analytics'
import { app } from '../src/utils/firebase'
import 'react-loading-skeleton/dist/skeleton.css'
import NextNProgress from 'nextjs-progressbar'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function MyApp({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    getAnalytics(app)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <FCMProvider>
        <SSRProvider>
          <TopicsProvider>
            <UserProvider>
              <NextNProgress
                color="#f7fafc"
                height={2}
                options={{
                  showSpinner: false,
                }}
              />
              <Component {...pageProps} />
            </UserProvider>
          </TopicsProvider>
        </SSRProvider>
      </FCMProvider>
    </QueryClientProvider>
  )
}

export default appWithTranslation(MyApp)
